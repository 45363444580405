import React, { useEffect, useState } from "react";
import { useCallback } from "react";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  let baseURL =
    // window.location.origin === "https://students.little.global"  ?
    window.location.origin === "http://localhost:3001"
      ? "https://corp.little.global/server"
      : "";
  // const useHttp = () => {
  //     const [isLoading, setIsLoading] = useState(false);
  //     const [error, setError] = useState(null);

  //     const sendRequest = useCallback(async (requestConfig, applyData) => {
  //         setIsLoading(true);
  //         setError(null);
  //         try {
  //             const response = await fetch(
  //                 baseURL + requestConfig.url, {
  //                 method: requestConfig.method,
  //                 headers: requestConfig.headers,
  //                 body: JSON.stringify(requestConfig.body)
  //             }
  //             );

  const sendRequest = useCallback(async (requestConfig, applyData) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://students.little.global/server" +
          // "http://localhost:3000" +
          // "https://corp.little.global/server"
          requestConfig.url,
        {
          method: requestConfig.method,
          //   headers: requestConfig.headers,
          headers: {
            "Content-Type": "application/json",
            ...requestConfig.headers, // Merge any additional headers
          },
          body: JSON.stringify(requestConfig.body),
          mode: "cors",
        }
      );

      if (!response.ok) {
        throw new Error("Request failed!");
      }

      const data = await response.json();
      applyData(data);
    } catch (err) {
      applyData(err.message || "Something went wrong!");
    }
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    sendRequest,
  };
};

export default useHttp;
